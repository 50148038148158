import { useEffect, useState, useCallback } from "react";
import ReactPixel from "react-facebook-pixel";
import RedditPixel from "react-reddit-pixel";
import { hotjar } from "react-hotjar";
import TagManager from "react-gtm-module";
import { usePostHog } from "posthog-js/react";
import { getCookieConsentValue } from "react-cookie-consent";
import config from "config/config.json";
import usePersistedState from "./usePersistedState";

function extractClickId(cookieString, platform) {
  switch (platform.toLowerCase()) {
    case "google":
      const gclMatch = cookieString.match(/_gcl_aw=GCL\.\d+\.(\d+)/);
      return gclMatch ? gclMatch[1] : null;

    case "facebook":
      const fbclMatch = cookieString.match(/_fbc=fb\.\d+\.\d+\.(\d+)/);
      return fbclMatch ? fbclMatch[1] : null;

    case "reddit":
      const rclMatch = cookieString.match(/_rdt_cid=(\w+)/);
      return rclMatch ? rclMatch[1] : null;

    default:
      return null;
  }
}

const useAnalytics = (user, setAuth, call, setTrackingIds) => {
  const posthog = usePostHog();
  const [consent, setConsent] = useState(false);

  const [currentTrackingIds, setCurrentTrackingIds] = usePersistedState(
    "trackingIds",
    {}
  );

  const cookieConsent = getCookieConsentValue(config.CONSENT_COOKIE);

  const redditClickId = extractClickId(document.cookie, "reddit");
  const facebookClickId = extractClickId(document.cookie, "facebook");

  useEffect(() => {
    if (consent) {
      setCurrentTrackingIds((prevState) => {
        return {
          ...prevState,
          ["rdt_cid"]: redditClickId,
          ["fbclid"]: facebookClickId,
        };
      });
    }
  }, [redditClickId, facebookClickId, consent]);

  useEffect(() => {
    setTrackingIds(currentTrackingIds);
  }, [currentTrackingIds]);

  useEffect(() => {
    const userConsent = user?.cookie_consent;
    setConsent(cookieConsent || userConsent);
  }, [user, cookieConsent]);

  const updateUserConsent = useCallback(async () => {
    if (consent && !!user?._id && !user?.cookie_consent) {
      const response = await call("updateUserInfo", {
        data: { cookie_consent: true },
      });
      if (response?.ok) {
        setAuth({ user: response.body }, true, false);
      }
    }
  }, [consent, user, call, setAuth]);

  useEffect(() => {
    updateUserConsent();
  }, [updateUserConsent]);

  useEffect(() => {
    if (consent) {
      initializeFacebookPixel(user);
      initializeRedditPixel(user);
      initializeHotjar(user);
      initializeGoogleTagManager(user);
      initializePostHog();
    }
  }, [consent, user]);

  const initializeFacebookPixel = (user) => {
    if (config.FACEBOOK_PIXEL) {
      ReactPixel.init(
        config.FACEBOOK_PIXEL,
        {
          em: user?.email?.toLowerCase().trim(),
          external_id: user?._id,
        },
        {
          autoConfig: true,
        }
      );
      if (consent) {
        ReactPixel.grantConsent();
      }
    }
  };

  const initializeRedditPixel = (user) => {
    if (config.REDDIT_PIXEL) {
      RedditPixel.init(config.REDDIT_PIXEL, {
        email: user?.email?.toLowerCase().trim(),
        externalId: user?._id,
      });
    }
  };

  const initializeHotjar = (user) => {
    if (config.HOTJAR_ID) {
      hotjar.initialize(config.HOTJAR_ID, "6");
      if (user) {
        hotjar.identify(user._id, {
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        });
      }
    }
  };

  const initializeGoogleTagManager = (user) => {
    if (config.GOOGLE_TAG_ID) {
      TagManager.initialize({ gtmId: config.GOOGLE_TAG_ID });
      if (user) {
        TagManager.dataLayer({
          dataLayer: {
            userId: user._id,
          },
        });
      }
    }
  };

  const initializePostHog = () => {
    if (config.PUBLIC_POSTHOG_KEY && config.PUBLIC_POSTHOG_HOST) {
      posthog.init(config.PUBLIC_POSTHOG_KEY, {
        api_host: config.PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
        autocapture: false,
      });
    }
  };

  const trackPageView = (pathname) => {
    if (consent) {
      if (config.FACEBOOK_PIXEL) {
        ReactPixel.pageView();
      }
      if (config.REDDIT_PIXEL) {
        RedditPixel.pageVisit();
      }
      trackTagManager("pageview", { pagePath: pathname });
    }
  };

  function trackTagManager(event, data = {}) {
    if (consent && !!config.GOOGLE_TAG_ID) {
      TagManager.dataLayer({
        dataLayer: {
          event,
          ...data,
        },
      });
    }
  }

  function trackPosthog(event) {
    posthog?.capture(event);
  }

  return {
    trackTagManager,
    posthog,
    consent,
    trackPageView,
    trackPosthog,
    redditClickId,
  };
};

export default useAnalytics;
